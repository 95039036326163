import React from 'react'

import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import Head from '../../components/head'

import Abstractphotography2 from './abstractphotography2.jpg'


export default class AbstractphotographyPage extends React.Component {
    constructor () {
        super();
        
        this._handleScroll = (e) => {
            let pageDown = '/work/abstractphotography3';
            let pageUp = '/work/abstractphotography'
        
            if (e.deltaY > 0 && pageDown) { // Runter scrollen
                navigate(pageDown);
            }
        
            if (e.deltaY < 0 && pageUp) { // Hoch scrollen
                navigate(pageUp);
            }
        }
        
        this._handleKey = (e) => {
            let keyRight = '/work/abstractphotography3';
            let keyLeft = '/work/abstractphotography'
        
            if (e.keyCode == 39 && keyRight) { // Key right
                navigate(keyRight);
            }
        
            if (e.keyCode == 37 && keyLeft) { // Key left
                navigate(keyLeft);
            }
        }
    }

    componentDidMount() {
        console.log('Component did mount')
        window.addEventListener('mousewheel', this._handleScroll);
        window.addEventListener('keydown', this._handleKey);
    }

    componentWillUnmount() {
        console.log('Component will unmount')
        window.removeEventListener('mousewheel', this._handleScroll);
        window.removeEventListener('keydown', this._handleKey);
    }

    render() {
        return (
            <Layout image={Abstractphotography2} footerImageDescription='A series of abstract photographys about perception.'>
                <Head title="Abstract Photography -"/>
            </Layout>
        )
    }
}